import Link from 'next/link';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const Custom404: React.ElementType = () => {
  return (
    <div className="mt-[20vh] mx-auto max-w-125">
      <div className="border border-outline-neutral-light rounded-lg shadow-light-down p-10 text-center">
        <ExclamationCircleIcon className="text-content-warning mb-4 mx-auto md:mb-6 h-10 w-10 md:h-12 md:w-12" />

        <h1 className="p-0 mb-3 font-normal text-[18px] leading-[1.4rem] md:text-[22px] leading-[1.6rem]">
          The page you&apos;re looking for doesn&apos;t exist
        </h1>

        <p className="p-0 m-0 text-body-lg leading-[1.2rem] md:text-body-lg leading-[1.3rem]">
          You can find our{' '}
          <Link href="/" className="underline hover:no-underline">
            active campaigns here
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

export default Custom404;
